import { render } from "./LayoutDefault.vue?vue&type=template&id=67bbffb4"
import script from "./LayoutDefault.vue?vue&type=script&lang=ts"
export * from "./LayoutDefault.vue?vue&type=script&lang=ts"

import "./LayoutDefault.vue?vue&type=style&index=0&id=67bbffb4&lang=scss"
script.render = render

export default script
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QHeader from 'quasar/src/components/header/QHeader.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QLayout,QHeader,QPageContainer});
