<template>
  <q-layout class="defaultLayoutContainer">
    <q-header class="bg-grey-10 hideOnPrint" elevated v-if="showHeader">
      <AppTopBar />
      <AppNavigation v-if="$route.params.project !== 'master'" />
      <div v-else style="height: 50px"></div>
    </q-header>

    <q-page-container>
      <RouterView v-slot="{ Component }">
        <transition
          enter-active-class="animated animate__fadeIn"
          leave-active-class="animated animate__fadeOut"
          mode="out-in"
          :duration="140"
        >
          <component :is="Component" />
        </transition>
      </RouterView>
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'
import AppNavigation from '@/components/AppNavigation.vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useApp } from '@/composable/useApp'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppTopBar,
    AppNavigation,
  },

  setup() {
    const { showHeader } = useApp()
    // Reload page to get latest permission rules
    onBeforeRouteLeave((to) => {
      window.location.pathname = to.path
    })

    return {
      showHeader,
    }
  },
})
</script>

<style lang="scss">
.printMode .defaultLayoutContainer {
  min-height: 0 !important;
}
</style>
