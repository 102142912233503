import { render } from "./LayoutMaster.vue?vue&type=template&id=062a2deb"
import script from "./LayoutMaster.vue?vue&type=script&lang=ts"
export * from "./LayoutMaster.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QDrawer from 'quasar/src/components/drawer/QDrawer.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDrawer,QScrollArea,QTable,QSeparator,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple});
