
import { defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'
import AppNavigation from '@/components/AppNavigation.vue'
import { onBeforeRouteLeave } from 'vue-router'
import { useApp } from '@/composable/useApp'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppTopBar,
    AppNavigation,
  },

  setup() {
    const { showHeader } = useApp()
    // Reload page to get latest permission rules
    onBeforeRouteLeave((to) => {
      window.location.pathname = to.path
    })

    return {
      showHeader,
    }
  },
})
