import { RouteRecordRaw } from 'vue-router'
import LayoutDefault from '@/layouts/LayoutDefault.vue'
import LayoutMaster from '@/layouts/LayoutMaster.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: {
      header: 'Logga in',
    },
    component: () =>
      import(/* webpackChunkName: "sign-in" */ '@/pages/SignIn.vue'),
  },
  {
    path: '/projects',
    name: 'projects',
    meta: {
      header: 'Projekt',
    },
    alias: '/',
    component: () =>
      import(/* webpackChunkName: "projects" */ '@/pages/Projects.vue'),
  },
  {
    path: '/:project',
    component: LayoutDefault,
    children: [
      {
        path: 'vehicle-withdrawal',
        name: 'vehicle-withdrawal',
        meta: {
          header: 'Avställning',
          permission: 'ui.navigation.vehicleWithdrawal',
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicle-withdrawal" */ '@/pages/VehicleWithdrawal.vue'
          ),
      },
      {
        path: 'canceled-tomorrow',
        name: 'canceled-tomorrow',
        meta: {
          header: 'Planerat inställda tåg',
          permission: 'ui.navigation.tools.plannedCancellation',
        },
        component: () =>
          import(
            /* webpackChunkName: "canceled-tomorrow" */ '@/pages/CanceledTomorrow.vue'
          ),
      },
      {
        path: 'vehicle-defects',
        name: 'vehicle-defects',
        meta: {
          header: 'Fordonsskador',
          permission: 'ui.navigation.tools.vehicleDefects',
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicle-defects" */ '@/pages/VehicleDefects.vue'
          ),
      },
      {
        path: 'operational-defects',
        name: 'operational-defects',
        meta: {
          header: 'Driftstörande fel',
          permission: 'ui.navigation.tools.operationalDefects',
        },
        component: () =>
          import(
            /* webpackChunkName: "operational-defects" */ '@/pages/OperationalDefects.vue'
          ),
      },
      {
        path: 'deviating-vehicle-composition',
        name: 'deviating-vehicle-composition',
        meta: {
          header: 'Avvikande Fordonssammansättning',
          permission: 'ui.navigation.tools.deviatingVehicleComposition',
        },
        component: () =>
          import(
            /* webpackChunkName: "deviating-vehicle-composition" */ '@/pages/DeviatingVehicleComposition.vue'
          ),
      },
      {
        path: 'deviating-vehicle-with-canceled',
        name: 'deviating-vehicle-with-canceled',
        meta: {
          header: 'Fordonsavvikelser med inställda tåg',
          permission: 'ui.navigation.tools.deviatingVehicleWithCanceled',
        },
        component: () =>
          import(
            /* webpackChunkName: "deviating-vehicle-with-canceled" */ '@/pages/DeviatingVehicleWithCanceled.vue'
          ),
      },
      {
        path: 'deviating-missing-distance',
        name: 'deviating-missing-distance',
        meta: {
          header: 'Avvikelser som saknar distans',
          permission: 'ui.navigation.tools.deviatingMissingDistance',
        },
        component: () =>
          import(
            /* webpackChunkName: "deviating-missing-distancen" */ '@/pages/DeviatingMissingDistance.vue'
          ),
      },
      {
        path: 'event',
        name: 'events',
        meta: {
          header: 'Händelser',
          permission: 'ui.navigation.event',
        },
        component: () =>
          import(/* webpackChunkName: "events" */ '@/pages/Events.vue'),
      },
      {
        path: 'event/:eventUuid',
        name: 'event',
        meta: {
          header: 'Händelse',
          permission: 'ui.navigation.event',
        },
        component: () =>
          import(/* webpackChunkName: "event" */ '@/pages/Event.vue'),
      },
      {
        path: 'graph/:trainId?/:departureDate?',
        name: 'graph',
        meta: {
          header: 'Graf',
        },
        component: () =>
          import(/* webpackChunkName: "graph-page" */ '@/pages/GraphPage.vue'),
      },
      {
        path: 'daily-report',
        name: 'daily-report',
        meta: {
          header: 'Dygnsrapport',
          permission: 'ui.navigation.dailyReport',
        },
        component: () =>
          import(
            /* webpackChunkName: "daily-report" */ '@/pages/DailyReport.vue'
          ),
      },
      {
        path: 'daily-report-preview',
        name: 'daily-report-preview',
        meta: {
          header: 'Dygnsrapport (PREVIEW)',
          permission: 'report.dailyReportInternal.preview',
        },
        component: () =>
          import(
            /* webpackChunkName: "daily-report-preview" */ '@/pages/DailyReportExternalPreview.vue'
          ),
      },
      {
        path: 'daily-report-external',
        name: 'daily-report-external',
        meta: {
          header: 'Dygnsrapport extern',
          permission: 'ui.navigation.dailyReportExternal',
        },
        component: () =>
          import(
            /* webpackChunkName: "daily-report-external" */ '@/pages/DailyReportExternal.vue'
          ),
      },
      {
        path: 'daily-report-external-v2',
        name: 'daily-report-external-v2',
        meta: {
          header: 'Dygnsrapport extern V2',
          permission: 'ui.navigation.dailyReportExternalV2',
        },
        component: () =>
          import(
            /* webpackChunkName: "daily-report-external-preview" */ '@/pages/DailyReportExternalPreview.vue'
          ),
      },
      {
        path: 'vehicle-availability',
        name: 'vehicle-availability',
        meta: {
          header: 'Fordonstillgänglighet',
          permission: 'ui.navigation.withdrawalDashboard',
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicle-availability" */ '@/pages/VehicleAvailability.vue'
          ),
      },
      {
        path: 'disruption-deviations',
        name: 'disruption-deviations',
        meta: {
          header: 'Störningshantering',
          permission: 'ui.navigation.deviation.disruption',
        },
        component: () =>
          import(
            /* webpackChunkName: "disruption-deviations" */ '@/pages/DisruptionDeviations.vue'
          ),
      },
      {
        path: 'vehicle-deviations',
        name: 'vehicle-deviations',
        meta: {
          header: 'Fordonsavvikelser',
          permission: 'ui.navigation.deviation.vehicle',
        },
        component: () =>
          import(
            /* webpackChunkName: "vehicle-deviations" */ '@/pages/VehicleDeviations.vue'
          ),
      },
      {
        path: 'cancel-deviations',
        name: 'cancel-deviations',
        meta: {
          header: 'Inställda tåg',
          permission: 'ui.navigation.deviation.cancel',
        },
        component: () =>
          import(
            /* webpackChunkName: "cancel-deviations" */ '@/pages/CancelDeviations.vue'
          ),
      },
      {
        path: 'crew-deviations',
        name: 'crew-deviations',
        meta: {
          header: 'Bemanning',
          permission: 'ui.navigation.deviation.crew',
        },
        component: () =>
          import(
            /* webpackChunkName: "crew-deviations" */ '@/pages/CrewDeviations.vue'
          ),
      },
      {
        path: 'delay-deviations',
        name: 'delay-deviations',
        meta: {
          header: 'Förseningar',
          permission: 'ui.navigation.deviation.delay',
        },
        component: () =>
          import(
            /* webpackChunkName: "delay-deviations" */ '@/pages/DelayDeviations.vue'
          ),
      },
      {
        path: 'masterdata',
        name: 'masterdata',
        component: LayoutMaster,
        meta: {
          header: 'Masterdata',
        },
        children: [
          {
            path: 'roles',
            name: 'master-roles',
            meta: {
              header: 'Masterdata roller',
              permissionPath: 'iam.role',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-roles" */ '@/pages/masterdata/Roles.vue'
              ),
          },
          {
            path: 'users',
            name: 'master-users',
            meta: {
              header: 'Masterdata användare',
              permissionPath: 'iam.user',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-users" */ '@/pages/masterdata/Users.vue'
              ),
          },
          {
            path: 'permissions',
            name: 'master-permissions',
            meta: {
              header: 'Masterdata behörigheter',
              permissionPath: 'iam.permission',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-permissions" */ '@/pages/masterdata/Permissions.vue'
              ),
          },
          {
            path: 'reasons',
            name: 'master-reasons',
            meta: {
              header: 'Masterdata orsaker',
              permissionPath: 'masterdata.reason',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-reasons" */ '@/pages/masterdata/Reasons.vue'
              ),
          },
          {
            path: 'vehicle-type-groups',
            name: 'master-vehicle-type-groups',
            meta: {
              header: 'Masterdata fordonstyper',
              permissionPath: 'masterdata.vehicleTypeGroup',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-type-groups" */ '@/pages/masterdata/VehicleTypeGroups.vue'
              ),
          },
          {
            path: 'vehicle-type-group-periods',
            name: 'master-vehicle-type-group-periods',
            meta: {
              header: 'Masterdata fordonstyp grupp perioder',
              permissionPath: 'masterdata.vehicleTypeGroupPeriod',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-type-group-periods" */ '@/pages/masterdata/VehicleTypeGroupPeriods.vue'
              ),
          },
          {
            path: 'vehicle-type-periods',
            name: 'master-vehicle-type-periods',
            meta: {
              header: 'Masterdata fordonstyp perioder',
              permissionPath: 'masterdata.vehicleTypePeriod',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-type-periods" */ '@/pages/masterdata/VehicleTypePeriods.vue'
              ),
          },
          {
            path: 'vehicle-types',
            name: 'master-vehicle-types',
            meta: {
              header: 'Masterdata fordonstyper',
              permissionPath: 'masterdata.vehicleType',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-types" */ '@/pages/masterdata/VehicleTypes.vue'
              ),
          },
          {
            path: 'customer-availabilty-status',
            name: 'master-customer-availability-status',
            meta: {
              header: 'Masterdata kundtillgänglighetsnivåer',
              permissionPath: 'masterdata.customerAvailabilityStatus',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-customer-availability-status" */ '@/pages/masterdata/CustomerAvailabilityStatus.vue'
              ),
          },
          {
            path: 'vehicles',
            name: 'master-vehicles',
            meta: {
              header: 'Masterdata fordon',
              permissionPath: 'masterdata.vehicle',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicles" */ '@/pages/masterdata/Vehicles.vue'
              ),
          },
          {
            path: 'master-vehicle-withdrawal-depots',
            name: 'master-vehicle-withdrawal-depots',
            meta: {
              header: 'Masterdata avställning depåer',
              permissionPath: 'masterdata.vehicleWithdrawalDepot',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-withdrawal-depots" */ '@/pages/masterdata/VehicleWithdrawalDepots.vue'
              ),
          },
          {
            path: 'master-vehicle-withdrawal-reasons',
            name: 'master-vehicle-withdrawal-reasons',
            meta: {
              header: 'Masterdata avställning orsaker',
              permissionPath: 'masterdata.vehicleWithdrawalReason',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-withdrawal-reasons" */ '@/pages/masterdata/VehicleWithdrawalReasons.vue'
              ),
          },
          {
            path: 'master-vehicle-withdrawal-responsibles',
            name: 'master-vehicle-withdrawal-responsibles',
            meta: {
              header: 'Masterdata avställning orsaker',
              permissionPath: 'masterdata.vehicleWithdrawalResponsible',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-withdrawal-responsibles" */ '@/pages/masterdata/VehicleWithdrawalResponsibles.vue'
              ),
          },
          {
            path: 'master-deviation-disruption-reasons',
            name: 'master-deviation-disruption-reasons',
            meta: {
              header: 'Masterdata störningsorsaker',
              permissionPath: 'masterdata.deviationDisruptionReason',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-deviation-disruption-reasons" */ '@/pages/masterdata/DeviationDisruptionReasons.vue'
              ),
          },
          {
            path: 'master-vehicle-defect-codes',
            name: 'master-vehicle-defect-codes',
            meta: {
              header: 'Masterdata fordonsskadekoder',
              permissionPath: 'masterdata.vehicleDefectCode',
            },
            component: () =>
              import(
                /* webpackChunkName: "master-vehicle-defect-codes" */ '@/pages/masterdata/VehicleDefectCodes.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/access-denied',
    component: () =>
      import(
        /* webpackChunkName: "access-denied" */ '@/pages/AccessDenied.vue'
      ),
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/projects',
  },
]
