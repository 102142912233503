<template>
  <div>
    <q-drawer persistent show-if-above :width="230" class="shadow-1">
      <q-scroll-area class="fit">
        <div
          style="height: 111px"
          class="q-px-md q-pt-md q-table__title text-grey-8"
        >
          Administrera
        </div>
        <q-separator />
        <q-list>
          <q-item
            clickable
            v-ripple
            v-for="item in masterdataItems"
            :to="{ name: item.name }"
            :key="item.name"
          >
            <q-item-section class="text-bold">{{ item.label }}</q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
    </q-drawer>

    <RouterView v-slot="{ Component, route }">
      <transition
        enter-active-class="animated animate__fadeIn"
        leave-active-class="animated animate__fadeOut"
        mode="out-in"
        :duration="100"
      >
        <component :is="Component" :key="route.path" />
      </transition>
    </RouterView>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import AppTopBar from '@/components/AppTopBar.vue'
import { useProfile } from '@/composable/useProfile'

export default defineComponent({
  name: 'LayoutDefault',

  components: {
    AppTopBar,
  },

  setup() {
    const { can, currentProject } = useProfile()

    const masterdataItems = computed(() =>
      [
        {
          label: 'Användare',
          name: 'master-users',
          permission: 'iam.user.list',
        },
        {
          label: 'Roller',
          name: 'master-roles',
          permission: 'iam.role.list',
        },
        {
          label: 'Orsaker',
          name: 'master-reasons',
          visible: currentProject.value?.name === 'master',
          permission: 'masterdata.reason.list',
        },
        {
          label: 'Behörigheter',
          name: 'master-permissions',
          permission: 'reges.admin.permission.list',
        },
        {
          label: 'Fordonstyp grupper',
          name: 'master-vehicle-type-groups',
          permission: 'masterdata.vehicleTypeGroup.list',
        },
        {
          label: 'Fordonstyper',
          name: 'master-vehicle-types',
          permission: 'masterdata.vehicleType.list',
        },
        {
          label: 'Fordonstyp grupp perioder',
          name: 'master-vehicle-type-group-periods',
          permission: 'masterdata.vehicleTypeGroupPeriod.list',
        },
        {
          label: 'Fordonstyp perioder',
          name: 'master-vehicle-type-periods',
          permission: 'masterdata.vehicleTypePeriod.list',
        },
        {
          label: 'Kundtillgänglighetsnivåer',
          name: 'master-customer-availability-status',
          permission: 'masterdata.customerAvailabilityStatus.list',
        },
        {
          label: 'Fordon',
          name: 'master-vehicles',
          permission: 'masterdata.vehicle.list',
        },
        {
          label: 'Avställning depåer',
          name: 'master-vehicle-withdrawal-depots',
          permission: 'masterdata.vehicleWithdrawalDepot.list',
        },
        {
          label: 'Avställning orsaker',
          name: 'master-vehicle-withdrawal-reasons',
          permission: 'masterdata.vehicleWithdrawalReason.list',
        },
        {
          label: 'Avställning ansvariga',
          name: 'master-vehicle-withdrawal-responsibles',
          permission: 'masterdata.vehicleWithdrawalResponsible.list',
        },
        {
          label: 'Störningsorsaker',
          name: 'master-deviation-disruption-reasons',
          permission: 'masterdata.deviationDisruptionReason.list',
        },
        {
          label: 'Fordonsskadekoder',
          name: 'master-vehicle-defect-codes',
          permission: 'masterdata.vehicleDefectCode.list',
        },
      ].filter(
        (item) =>
          can(item.permission) &&
          (typeof item.visible === 'boolean' ? item.visible : true)
      )
    )

    return { masterdataItems }
  },
})
</script>
